.brand-main  img {
    height: 25px;
}

.nav-toggle {
    background-color: $header-buttons-bg-color;
    border-radius: 2px;
    color: $header-buttons-font-color;
    display: none;
    font-size: 14px;
    line-height: 9px;
    padding: 6px;

    &:active,
    &:focus,
    &:visited {
        color: $header-font-color;
    }
}

.action-list {
    float: right;
    list-style: none;
    margin: 0;

    li {
        display: block;
        float: left;
        position: relative;
        height: 36px;
        margin: 0;
        padding: 0 4px;

        &.action-item a {
            padding: 11px;
        }
        .icon {
            color: $sidebar-icon-color;
        }

        a {
            background-color: $header-buttons-bg-color;
            border-radius: 2px;
            color: $header-buttons-font-color;
            display: inline-block;
            font-size: 14px !important;
            line-height: 14px !important;
            padding: 11px !important;
        }

        a:hover {
            background-color: $header-buttons-bg-color--hover;
            color: $header-font-color;
        }

    }

    .avatar {
        padding: 0 !important;
        background-color: transparent;
    }

    .avatar img {
        width: 36px;
        height: 36px;
        border-radius: 2px;
    }

    .dropdown-group {
        background-color: $header-buttons-bg-color;

        i {
            text-align: center;
            width: auto;
        }

        &.has-child > .dropdown-activator::after {
            content: none;
        }
        .dropdown-group-item i {
            width: 25px;
        }

    }
     .notification-dropdown {
        padding: 5px !important;

        .dropdown-header {
            border-bottom: 1px solid $ls-color-gray--dark;
            color: $ls-color-primary;
            padding: 3px 10px 8px;
            margin-bottom: 0;
        }
    }
}

@media(max-width: $small-breakpoint-below) {

    .nav-toggle {
        display: inline-block;
        margin-left: 10px;
    }

    .brand-main {
        float: left;

        img {
            height: 36px;
            margin-bottom: 3px;
        }
    }
}
