.btn {
    &.btn-pressable:active {
        transform: translate(0px, 3px);
        -webkit-transform: translate(0px, 3px);
    }

    font-family: $base-font-family;
}

// Disable Outline on focus
.btn:focus, .btn.focus, .btn:active:focus, .btn:active.focus, .btn.active:focus, .btn.active.focus {
    outline: none;
}

.btn-default {
    border: 1px solid $ls-color-gray;
    background-color: $white;

    &:hover, &:active, &:focus {
        background-color: $ls-color-gray--light;
    }
}

.btn-outline-default {
    border: 1px solid $ls-color-gray;
    background-color: $white;

    &:hover, &:active, &:focus {
        background-color: $ls-color-gray--light;
    }
}

.btn-theme {
    @include button-variant($ls-color-primary, $ls-color-primary);
    $color: $ls-color-secondary;
}

.btn-outline-theme {
    @include button-outline-variant($ls-color-primary);
}

.btn-theme-light {
    @include button-variant($ls-color-primary--light, $ls-color-primary--light);
    $color: $white;
}

// Rounded Button
.btn-rounded {
    border-radius: 20px;
}

.btn i {
    width: 1.2em;
    text-align: left;
}

.btn-icon {
    padding: 0.6rem;
    line-height: 1em;

    i {
        text-align: center;
    }
}

// Sizes
.btn-xs {
    font-size: 12px;
    padding: 3px 10px;
}

.btn-full {
    width: 100%;
}

// Extra

.ladda-button {
    overflow: visible !important;
}


// Color Overrides
.btn-success,.btn-warning, .btn-info {
    color: $white;

    &:hover {
        color: $white;
    }
}

//dropdown Button
.btn-group .btn-group .dropdown-group {

    .btn {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    .btn-group:not(:last-child) > .btn {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
}
.btn-group-vertical {
    > .btn-group:not(:last-child) .dropdown-group .btn{
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }
    > .btn-group .dropdown-group .btn{
        border-top-right-radius: 0;
        border-top-left-radius: 0;
    }
}

.btn-crater {
    width: 148px;
    height: 45px;
    border-radius: 5px;
    font-family: poppins;
    font-weight: 600;
    font-size: 15px;

    .btn-label {
        margin: 0 0 0 0;
    }
}

.btn-crater:focus {
    outline: 10px auto #817AE3;
}

.btn-crater:disabled {
    opacity: 0.5;
}

.btn-crater-default {
    color: #FFFFFF;
    background: $ls-color-primary;
    border: 1px solid $ls-color-primary;

    .icon-fa-spinner {
        color: #ffffff;
    }
}

.btn-crater-default:hover {
    background: #635BF2;
}

.btn-crater-hollow {
    border: 1px solid $ls-color-primary;
    color: $ls-color-primary;
    background: #ffffff;

    .icon-fa-spinner {
        color: $ls-color-primary;
    }
}

.btn-crater-hollow:hover {
    opacity: 0.9;
    color: $ls-color-primary;
}
