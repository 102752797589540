.base-date-input {
    width: 100%;
    position: relative;

    .date-field {
        width: 100%;
        height: 40px;
        background: #FFFFFF;
        border: 1px solid $ls-color-gray--light;
        box-sizing: border-box;
        border-radius: 5px;
        display: inline-block;
        padding: 0px 6px 0px 40px;
        font-size: 1rem;
        line-height: 1.4;
        cursor: pointer;

        &:focus {
            border: 1px solid #817AE3;
        }

        &.invalid {
            border: 1px solid #FB7178 !important;
        }
    }

}

.vdp-datepicker__calendar-button {
    position: absolute;
    width: 13px;
    height: 18px;
    color: $ls-color-gray;
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    line-height: 16px;
    top: 50%;
    left: 20px;
    transform: translate(-50%, -50%);

    .icon-fa {
        color: $ls-color-gray;
    }
}
