.skin-crater {
    // Theme Colors :
    $ls-color-theme: $ls-color-primary;

    //HEADER SECTION
    $header-bg-color: $primary-to-primary-light-gradient;
    $header-font-color: $ls-color-black;
    $header-brand-font-color: $white;
    $header-buttons-bg-color: $white;
    $header-buttons-bg-color--hover: darken($white, 5%);
    $header-buttons-font-color: $ls-color-black;

    //SIDEBAR SECTION
    $sidebar-bg-color: $white;
    $side-bar-border-right: $ls-color-gray--light;
    $sidebar-font-color: $ls-color-black;
    $sidemenu-item-bg-color--hover: darken($ls-color-gray--light, 5%);
    $sidemenu-item-bg-color--active: transparent;
    $sidemenu-item-font-color: $ls-color-black;
    $sidemenu-item-font-color--active: $ls-color-theme;
    $sidemenu-item-icon-color: $ls-color-gray--dark;
    $sidemenu-item-icon-color--active: $ls-color-primary;
    $sidemenu-arrow-color: $ls-color-gray--dark;
    $sidemenu-submenu-bg: $ls-color-gray--light;

    //Horizontal Menu
    $header-bottom-bg: $white;
    $header-menu-item-font-color: $ls-color-black;
    $header-menu-item-font-color--active: $ls-color-theme;
    $header-menu-item-bg-color--hover: darken($ls-color-gray--light, 5%);
    $header-menu-item-bg-color--active: transparent;

    //DROPDOWN
    $dropdown-bg: $white;
    $dropdown-bg--hover: $ls-color-gray--light;
    $dropdown-border-color: $ls-color-gray--dark;
    $dropdown-divider-bg: $ls-color-gray--dark;
    $dropdown-activator-color: $ls-color-black;
    $dropdown-activator-color--hover: $ls-color-theme;
    $dropdown-header-color: $ls-color-theme;
    $dropdown-header-border-color: $ls-color-gray--light;

    //MISC
    $code-bg: $ls-color-gray--light;
    $code-color: $ls-color-theme;

    //MAILBOX
    $mailbox-sidebar-menu-item: $ls-color-theme;
    $mailbox-font-color:$ls-color-black;
    $mailbox-page-link: $white;
    $mailbox-page-link-bg: $ls-color-theme;

    .main-content {
        background: $ls-color-gray--very-light;
    }

    .no-data-info {

        .title {
            font-weight: 500;
            font-size: 17.5px;
        }

        .description {
            font-size: 15px;
            color: $ls-color-gray--very-dark;
        }
    }


    //HEADER
    .site-header {
        background: $header-bg-color !important;
        border-bottom: 1px solid rgba(230, 230, 230, 0.7);

        .brand-main {
            color: $header-brand-font-color;
            font-size: 20px;
            font-family: poppins, sans-serif;
            font-style: normal;
            font-weight: 900;
            letter-spacing: 1px;
        }

        .nav-toggle {
            background-color: $header-buttons-bg-color;
            color: $header-buttons-font-color;
        }

        .hamburger span {
            background: $header-buttons-font-color;
        }

        .hamburger-inner,
        .hamburger-inner::before,
        .hamburger-inner::after  {
            background-color: $header-font-color;
        }

        .hamburger span::before,
        .hamburger span::after {
            background-color: $header-buttons-font-color;
        }

        .action-list li {
            a {
                background-color: $header-buttons-bg-color;
                color: $header-buttons-font-color;
            }

            a:hover {
                background-color: $header-buttons-bg-color--hover;
                color: $header-buttons-font-color;
            }
        }

        .dropdown-menu {
            background-color: $white;
        }

        .notification-dropdown .dropdown-header {
            border-bottom: 1px solid $dropdown-header-border-color;
            color: $dropdown-header-color;
        }

        .dropdown-divider {
            background-color: $dropdown-divider-bg;
        }
    }

    //HEDER BOTTOM
    .header-nav {

        .dropdown-group.dropdown-group.active > .dropdown-activator > a {
            color: $dropdown-header-color;
        }
        .dropdown-group.has-child.toggle-arrow.active > .dropdown-activator::after {
            border-color: $dropdown-header-color transparent transparent;
        }
    }

    //SIDEBAR/NAV

    .sidebar-left {
        background-color: $sidebar-bg-color;
        border-right: 1px solid $side-bar-border-right;
    }

    .side-nav {
        margin: 20px 0 0 0;

        .side-nav-division {
            margin-bottom: 20px;
        }

        .collapse-group .collapse-group-item {
            a {
                color: $ls-color-black;
                font-family: poppins, sans-serif;
                font-size: 14px;

                &:hover {
                    background-color: $sidemenu-item-bg-color--hover;
                }
            }

            &.active > .collapse-item-title > a {
                color: $sidemenu-item-font-color--active;
            }

            .collapse-item-title i {
                color: $sidemenu-item-icon-color;
            }

            &.active i {
                color: $sidemenu-item-icon-color--active
            }

            .collapse-group-items {
                background-color: $sidemenu-submenu-bg;

                .collapse-group-items {
                    background-color: darken($sidemenu-submenu-bg, 5%);

                    .collapse-group-items {
                        background-color: darken($sidemenu-submenu-bg, 8%);
                    }
                }

                a.active {
                    color: $sidemenu-item-font-color--active;
                }
            }
        }
    }

    //Horizontal Layout
    .header-bottom {
        background-color: $header-bottom-bg;
        border-bottom: 1px solid $ls-color-gray;
    }

    .header-nav {
        li a {
            color: $header-menu-item-font-color;

            &:hover {
                background-color: $header-menu-item-bg-color--hover;
                color: $header-menu-item-font-color;
            }
        }

        li.active > a {
            color: $header-menu-item-font-color--active;
            background-color: $header-menu-item-bg-color--active;

            &:hover {
                color: $header-menu-item-font-color--active;
                background-color: $header-menu-item-bg-color--active;
            }
        }

        li .dropdown-item.active {
            color: $header-menu-item-font-color--active;
            background-color: $header-menu-item-bg-color--active;

            &:hover, &:focus, &:active {
                color: $header-menu-item-font-color--active;
                background-color: $header-menu-item-bg-color--hover;
            }
        }
    }

    //Tabs

    // Tabs Default
    //----------------------------------

    .tabs-component.tabs-default {
        .tabs-component-tab {
            a {
                color: $ls-color-black;
            }

            a:hover {
                color: $ls-color-black;
            }

            &.is-active {
                a {
                    color: $white;
                }

                border: 1px solid $ls-color-theme;
                background: $ls-color-theme;
                color: $white;
            }
        }

        .tabs-component-tab:hover {
            border: 1px solid $ls-color-theme;
        }

        .tabs-component-panels {
            border-top: 1px solid $ls-color-theme !important;
        }
    }

    // Tabs Vertical
    //----------------------------------

    .tabs-component.tabs-vertical {
        .tabs-component-tabs {
            border-right: 1px solid $ls-color-theme !important;

            .tabs-component-tab {
                a, a:hover {
                    color: $ls-color-black;
                }

                &.is-active {
                    a {
                        color: $white;
                    }

                    background: $ls-color-theme;
                }
            }

            .tabs-component-tab:hover {
                border: 1px solid $ls-color-theme;
            }
        }
    }

    // Tabs Simple
    //----------------------------------

    .tabs-component.tabs-simple {
        .tabs-component-tabs .tabs-component-tab {
            a {
                color: $ls-color-gray--dark;
                font-weight: 500;
            }

            a:hover {
                color: $ls-color-gray--dark;
                border-bottom: 3px solid $ls-color-gray--dark;
            }

            &.is-active {
                border-bottom: 3px solid $ls-color-theme;
                color: $white;
            }

            &.is-active a {
                color: $ls-color-black;
            }
        }

        .tabs-component-panels {
            border-top: 1px solid $ls-color-gray--light !important;
        }
    }

    // Tabs Simple Vertical
    //----------------------------------

    .tabs-component.tabs-simple-vertical .tabs-component-tabs {
        border-right: 1px solid #ddd;

        .tabs-component-tab {
            a {
                color: $ls-color-gray--dark;
                font-weight: 500;
            }

            a:hover {
                border-right: 3px solid lighten($ls-color-theme, 30%);
            }

            &.is-active {
                border-right: 3px solid $ls-color-theme;
                color: $ls-color-theme;
            }
        }
    }

    //MISC
    code, .code {
        background-color: $code-bg;
        color: $code-color;
    }

    mark, .mark {
        background-color: $ls-color-theme;
        color: $white;
    }

    .todo-container {
        h5 {
            color: $white !important;
        }

        background-color: $ls-color-theme !important;
        color: $white !important;
    }

    // Dropzone

    .vue-dropzone .dz-preview {
        .dz-details {
            background-color: $ls-color-theme !important;
            color: $white !important;
        }

        .dz-upload {
            background: $white !important;
        }

        .dz-error-mark svg #Page-1 g {
            fill: $white !important;
        }

        .dz-success-mark svg g path {
            fill: white !important;
        }

        .dz-remove {
            color: $white !important;
            border: 2px $white solid !important;
        }
    }

    //Dropdown
   .dropdown-group {

        &.active > .dropdown-activator > a {
            color: $dropdown-activator-color--hover;
        }

        .dropdown-activator a {
            color: $dropdown-activator-color;
        }

        .dropdown-activator a:hover {
            background-color: $dropdown-bg--hover;
        }

        &.has-child.toggle-arrow.active > .dropdown-activator::after {
            border-color: $dropdown-activator-color--hover transparent transparent;
        }

        .dropdown-container {
            color: $dropdown-activator-color;
            background-color: $dropdown-bg;

            .dropdown-group-item .dropdown-item {
                color: $dropdown-activator-color;
                background-color: transparent;
                cursor: pointer;
            }

            .dropdown-group-item .dropdown-item.active {
                color: $dropdown-activator-color--hover;
            }

            .dropdown-group-item .dropdown-item:hover {
                background-color: $dropdown-bg--hover;

            }

            .dropdown-activator::before {
                border-color: $dropdown-activator-color transparent transparent;
            }
        }

        .dropdown-container .dropdown-group.active .dropdown-activator {

            a {
                color: $dropdown-activator-color--hover;
            }

            &::before {
                border-color: $dropdown-activator-color--hover transparent transparent;
            }
        }

        .dropdown-item-divider {
            border-top: 1px solid $dropdown-divider-bg;
        }
    }

    //Buttons
    .btn-theme {
        // @include button-variant($ls-color-theme, $ls-color-theme);
        background: $ls-color-theme;
        color: $white;

        &:hover {
          color: $white;
          background: lighten($ls-color-theme, 4%);
        }
    }


    .btn-theme-outline {
        @include button-outline-variant($ls-color-theme);
    }

    //Forms
    .form-control:focus {
        border: 1px solid $ls-color-theme;
    }

    //Vue Select
    .multiselect {
        color: $ls-color-black;

        .multiselect__option--highlight {
            background: $ls-color-gray--very-light;
            color: $ls-color-black;

            &.multiselect__option--selected {
                background: $ls-color-gray--light;
                color: $ls-color-black;

                &::after {
                    background: $ls-color-gray;
                    color: $ls-color-black;
                }
            }

            &::after {
                background: $ls-color-gray;
                color: $ls-color-black;
            }
        }

        .multiselect__tags-wrap {
            .multiselect__tag {
                background: $ls-color-theme;
                color: $white;

                .multiselect__tag-icon {
                    &:hover {
                        background: $ls-color-theme;
                    }

                    &::after {
                        color: $white;
                    }
                }
            }
        }
    }

    //DATEPICKER
    .vdp-datepicker__calendar .cell {

        &:not(.blank):not(.disabled) {

            &.day:hover,
            &.month:hover,
            &.year:hover {
                border: 1px solid $ls-color-theme !important;
            }
        }

        &.selected {
            background: $ls-color-theme !important;
            color:$white;
        }

        &.highlighted {
            background:lighten($ls-color-theme,25%) !important;
            color:$white;

        }
    }

    // Vue-Carousel
    .VueCarousel-dot {

        .VueCarousel-dot-button {
            background: $ls-color-gray--dark!important;
        }

        &.VueCarousel-dot--active {
            .VueCarousel-dot-button {
                background: $ls-color-theme !important;
            }
        }
    }


    //MAILBOX
    .mailbox .mailbox-content {

        .table tr td .content-subject {
            color: $mailbox-font-color;
        }

        .mailbox-content-footer {

            .page-link {
                color: $mailbox-font-color;
            }

            .active .page-link {
                background-color: $mailbox-page-link-bg;
                border-color: $mailbox-page-link-bg;
                color: $mailbox-page-link;
            }
        }

        .btn-favorite .active {
            color: $ls-color-theme;
        }
    }

    .mailbox-modal .mail-body .message-heading.email a {
        color: $ls-color-black;
    }

    .mailbox .mailbox-sidebar {

        .sidebar-menu li.active {
            border-left: 5px solid $ls-color-theme;
        }
        .sidebar-menu li a{
            color: $mailbox-font-color;
        }

        .left-sidebar-btn {
            color: $mailbox-font-color;
        }
    }

    @media (max-width: $x-small-breakpoint) {
        .header-nav .dropdown-menu {
            border: 0 !important;
            background-color: $sidemenu-submenu-bg !important;
        }
    }
}
